import React from "react";
import ContForm from "./cont-form";
import { faSquareFull } from "@fortawesome/free-solid-svg-icons";

// icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


// images
import Logo from '../assetes/images/get-labourers.png';
import grass from '../assetes/images/grass.png';

export default function Footer() {
    return (
        <div>
            <footer class="footer mt-5 py-5">

                {/* call and whats app buttons in the right side */}

                <div className="clWa">
                    <div className="call">
                        <a href="tel:+91 7676038974" target="blank">
                            <FontAwesomeIcon icon="fa-solid fa-phone" />
                        </a>
                    </div>

                    <div className="call">
                        <a href="tel:+91 7676038974">
                            <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                        </a>
                    </div>

                </div>



                <div className="container ftr-cont text-white">
                    <div className="row pt-5 pb-3  px-4">
                        <div className="col-md-3 fot-icon text-start">
                            <img src={Logo} alt="footer logo" title="" className="w-75 footer-logo ms-lg-0" loading="lazy" />
                            <h5 className="fw-bold mt-4 ftr-hed">Site Address</h5>
                            <p className="mainaddress pe-md-4">
                                Hoskoteya ,Bangalore Rural , Karnataka - 562114
                            </p>

                            <p>
                                <a href="tel:+91 7676038974" className="text-white">
                                    <FontAwesomeIcon icon="fa-solid fa-phone" className="pe-2" /> +91 7676038974
                                </a>
                            </p>

                            <p>

                                <a href="mailto:getlabourers@gmail.com" className="text-white" target="blank">
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" className="pe-2" />  getlabourers@gmail.com
                                </a>

                            </p>
                            <div className="social-media sm-ftr pe-lg-4">

                                <a href="https://www.instagram.com/get_labourers/profilecard/?igsh=Y3g1ZGxjOGdtbWph" target="blank">
                                    <FontAwesomeIcon icon="fa-brands fa-instagram " className="text-white" />
                                </a>

                                <a href="https://www.facebook.com/profile.php?id=61567424084469" target="blank">
                                    <FontAwesomeIcon icon="fa-brands fa-facebook-f " className="text-white" />
                                </a>

                                {/* <a href="http://">
                                    <FontAwesomeIcon icon="fa-brands fa-linkedin-in " className="text-white" />
                                </a> */}

                                <a href="tel:+91 7676038974"  target="blank">
                                    <FontAwesomeIcon icon="fa-brands fa-whatsapp" className="text-white" />
                                </a>

                            </div>

                        </div>
                        <div className="col-12 col-md-6 mt-md-4 mt-lg-0 text-start"
                        >

                            <div className="ftr-ser">
                                {/* <h5 className="fw-bold mt-4"></h5> */}
                                <h4 className="pb-3 fw-bold ftr-hed">Our Services</h4>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <a href="diggingservices">
                                                    Digging Services
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/estate-cleaning">
                                                    Landscaping Services
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/weedRemoval">
                                                    Weed Removal
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/outdoorCleaning">
                                                    Outdoor Cleaning
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/irrigationServices">
                                                    Irrigation System Installation
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/diggingservices">
                                                    Pit Digging
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/diggingservices">
                                                    Sump Digging
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/outdoorCleaning">
                                                    Garbage Cleaning
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/mosquitoFogging">
                                                    Mosquito Fogging
                                                </a>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="col-6">
                                        <ul>




                                            {/* <li>
                                                <a href="">
                                                    Gutter Cleaning
                                                </a>
                                            </li>

                                            <li>
                                                <a href="">
                                                    Sewage Cleaning
                                                </a>
                                            </li> */}


                                            <li>
                                                <a href="/contactUs">
                                                    Farming Assistance
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/estate-cleaning">
                                                    Tree Trimming and Pruning
                                                </a>
                                            </li>


                                            <li>
                                                <a href="/organic-manuring-soil-enrichment">
                                                    Soil Testing Services
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/organic-manuring-soil-enrichment">
                                                    Compost Pit Setup
                                                </a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>




                            </div>



                        </div>


                        <div className="col-12 col-md-3 md-mt-0 mt-md-4 mt-lg-0 wow fadeInRight" data-wow-delay=".6s"
                        >
                            <h4 className="pb-3 fw-bold ftr-hed">
                                Contact To Know More



                            </h4>
                            <ContForm />

                        </div>
                    </div>

                </div>


                <img src={grass} alt="" className="grass1" />

                <img src={grass} alt="" className="grass2" />

            </footer>

        </div>



    )

}