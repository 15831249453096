import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import TestiStr from './testiStruc';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import './styles.css';
import frPaul from '../assetes/images/client-img/fr-paul.jpg';
import srikanth from '../assetes/images/client-img/srikanth.jpg';
import jophy from '../assetes/images/client-img/jophy.jpg';
import srihari from '../assetes/images/client-img/srihari.jpg';


// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function Testimonials() {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                breakpoints={{
                    // Set for mobile screens up to 768px
                    0: {
                        slidesPerView: 1, // Show one slide on mobile
                        spaceBetween: 10, // Adjust spacing as needed for mobile
                    },
                    // For screens larger than 768px
                    768: {
                        slidesPerView: 3, // Show three slides
                        spaceBetween: 50, // Regular spacing
                    },
                }}
            >
                <SwiperSlide>

                    <TestiStr

                        clientImg={srikanth}

                        testimonial="I recently hired their team for weed removal, cricket pitch leveling, and stone removal, and the results were outstanding! The work was done efficiently and with great attention to detail, leaving everything in perfect condition. I’m thoroughly impressed and highly recommend their services!"

                        clientName="Srikanth"

                        clientProfession="- Cricket Coach"

                    />

                </SwiperSlide>
                <SwiperSlide>
                    <TestiStr

                        clientImg={frPaul}

                        testimonial="I recently hired their team for school ground cleaning, weed removal, and organic manuring for the garden, and I’m truly impressed with their work! The ground looks spotless, the garden is thriving, and everything was handled with such care and professionalism."

                        clientName="FR.M.U.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TestiStr

                        clientImg={jophy}

                        testimonial="I had their team set up a drip irrigation system on my garden, and I couldn’t be happier with the results! The installation was seamless, and the quality of the materials used is outstanding. My crops are thriving, and the water efficiency is incredible. Highly recommend their service!"

                        clientName="Jophy Empson"
                        clientProfession="-"
                        // clientProfession="- Cricket Coach"

                    />
                </SwiperSlide>

                <SwiperSlide>
                    <TestiStr

                        clientImg={srihari}

                        testimonial="I hired their team to clean up my real estate plot, which was overgrown with unwanted plants, and they did an incredible job! The entire area was thoroughly cleared and left in perfect condition. Their professionalism and attention to detail were outstanding."

                        clientName="Srihari"
                        clientProfession="-"

                        // clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>



            </Swiper>
        </>
    );
}