import React from "react";
// import contactbanner from "../assetes/images/banner-img/contact-banner.png";


import banner from '../../assetes/images/services/realestate-cleaning/realestate-cleaning.png'


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import dripIrrgaition from "../../assetes/images/services/realestate-cleaning/weed-control.png";
import sprinklerIrrgation from "../../assetes/images/services/realestate-cleaning/land-clearing.png";
import customIrrigation from "../../assetes/images/services/weed-removal/chemical-weed-control.png";


import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function EstateCleaning() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="pt-1">

            <div className="abt-banner pt-5 mt-5">
                <img src={banner} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        <span> Plots </span>
                        And <span>realestate</span> cleaning</p>
                </div>
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Plots & Real Estate Cleaning Services
                    </h1>

                    <h2 className="ser-sub-hed">
                        Clearing the Way for a Cleaner, Better Property
                    </h2>

                    <p className="ser-cm-fnt">
                        Our Plots and Real Estate Cleaning service is designed to keep your land and properties in pristine condition. Whether you own a small plot, a large estate, or a commercial real estate property, our team is equipped to handle the clearing, cleaning, and maintenance of your land.
                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                Get Labourers offers expert, reliable cleaning services for plots and real estate properties of all sizes. With years of hands-on experience, we provide thorough, efficient clearing and maintenance to ensure your land is pristine and prepared for any future plans. Our team understands the unique needs of each property, delivering customized solutions with a focus on quality, eco-friendly practices, and customer satisfaction. Trust Get Labourers to handle your property with care and precision.
                            </p>
                        </div>

                    </div>

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Enhanced Property Value
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Time and Cost Efficiency
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Safety and Compliance
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Customized Solutions
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Plots & Real Estate Cleaning Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                {/* <span> Weed and Vegetation Control </span> */}
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                <span >1. Weed and Vegetation Control</span><br />
                                Elimination of unwanted weeds and overgrown vegetation to keep the property neat, prevent pest infestations, and reduce fire risks.

                            </p>

                            <p>
                                <span >2. Tree and Shrub Trimming</span><br />
                                Pruning of trees and shrubs for a clean, tidy look and to enhance safety and visibility on the property.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >3. Lawn and Ground Maintenance</span><br />
                                Regular upkeep of grass, groundcover, and soil to maintain a neat appearance and prevent overgrowth.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >4. Pest Control and Prevention</span><br />
                                Treatment to reduce pests such as rodents, mosquitoes, and insects, particularly in overgrown areas that may attract wildlife.
                            </p>



                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={dripIrrgaition} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={sprinklerIrrgation} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >5. Soil Leveling and Grading</span><br />
                                Leveling uneven land to prepare for construction or to improve drainage, preventing water buildup and erosion.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >6. Drainage and Erosion Control</span><br />
                                Installation of basic drainage solutions to protect the land from erosion and flooding, especially useful for larger estates.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >7. Post-Construction Cleaning</span><br />
                                Clearing leftover construction materials, dust, and debris to ensure a clean, safe environment after building work is completed.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                <span >7. Land Clearing</span><br />
                                Removal of debris, rocks, brush, and other obstructions to prepare plots for development or landscaping.
                            </p>

                            <FormPop />

                        </div>
                    </div>



                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What is the best method for weed removal?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    The best method depends on the type of weeds, the size of the area, and your preferences. We offer manual removal, chemical control, plastic mulching, and soil solarization. Our experts will assess your space and recommend the most effective and eco-friendly solution.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. What does your plot and real estate cleaning service include?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Our cleaning services include removal of debris, overgrown vegetation, and weeds. We also handle garbage removal, outdoor area cleaning, and can prepare plots for future construction or landscaping.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. Why should I invest in plot cleaning services?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Regular plot cleaning prevents overgrowth, minimizes pest risks and ensures that the area is ready for potential development. It also enhances the property’s appearance and value, making it more attractive to potential buyers or tenants.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. How often should I schedule a plot cleaning service?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    For large or heavily vegetated plots, we recommend scheduling cleaning services once every two months to keep the area well-maintained. Smaller plots might require once every three months, depending on the vegetation and upkeep needs.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                5. Do you offer one-time cleaning or recurring maintenance plans?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we offer both one-time cleaning services and recurring maintenance plans. Our team can work with you to create a schedule that meets your needs, whether it’s a one-off job or ongoing maintenance.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                6. Are there any specific types of properties you specialize in cleaning?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We specialize in cleaning residential plots, commercial properties, vacant lands, and real estate intended for sale or rental. Our services are adaptable to various types of plots and real estate properties.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                            7. Do you provide waste disposal services for the debris removed?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we handle all aspects of waste removal and disposal, including safe removal of all debris, plant material, garbage, and any other unwanted materials from your property.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>
                                8.How much do your plot cleaning services cost?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Pricing varies based on the size, condition, and specific cleaning requirements of the property. We offer free quotes, so feel free to contact us for a customized estimate.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>
                                9. What should I do to prepare my property for cleaning?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    You don’t need to do much! Our team will handle everything from start to finish. However, if there are any valuable or delicate items on-site, we recommend removing or securing them before our team arrives.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>
                                10. Is your team equipped for overgrown or large-area cleaning jobs?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, our team is experienced and equipped with the necessary tools and machinery to handle heavily overgrown areas and large plots, ensuring thorough and efficient cleaning.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>



                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default EstateCleaning;