import React from "react";
import Helmot from "../assetes/images/helmet.svg";
import "../assetes/logoDes.css";

function Header2() {
    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="log-descont">
                    <img src={Helmot} className="log-hel" />

                    <p className="comp-name">
                        Get Labourers
                    </p>

                </div>
            </div>
        </>
    )
}


export default Header2;


