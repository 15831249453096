import React from "react";
// import contactbanner from "../assetes/images/banner-img/contact-banner.png";
import { OurServices, SerCatgory } from '../../components/services';

import banner from '../../assetes/images/services/realestate-cleaning/realestate-cleaning.png';
import AllServices from "../allServices";

// images
import ser1Img from '../../assetes/images/services/banner1.png';
import sweeping from '../../assetes/images/services/sweeping.jpg';
import farming from '../../assetes/images/services/farming-assistance.png';
import pestControl from '../../assetes/images/services/weed-removal/chemical-weed-control.png';



function TotSer() {
    // gallery images
    return (
        <section className="pt-1">

            <div className="abt-banner pt-5 mt-5">
                <img src={banner} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        Our Srvices
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="ser-intro pt-5 pb-2">

                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Our Srvices
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Dependable Hands for Every Task!"
                    </h2>

                    <p className="ser-cm-fnt">
                        We specialize in offering a wide range of professional labor services designed to simplify your everyday tasks. Whether you need digging, pit or sump creation, weed removal, outdoor cleaning, or garbage clearing, our experienced team is here to help. We also provide expert solutions for mosquito fogging, gutter cleaning, sewage management, farming assistance, and organic manuring. Our irrigation services, including drip and sprinkler system installations, ensure efficient water management for your agricultural needs. With a focus on quality and efficiency, we deliver dependable services tailored to your requirements.
                    </p>

                </div>



                <AllServices />

                <div className="row">

                    <div className='col-lg-4'>

                        <OurServices
                            ser={pestControl}

                            serName="Plant Pest Control"
                            serlink="/plant-pest-control"
                        >
                            <p className="serMinCont">
                                We provide effective plant pest control services, from targeted treatments for common garden pests to preventive care solutions, ensuring your plants stay healthy and thriving.
                            </p>

                        </OurServices>


                    </div>

                    <div className='col-lg-4'>

                        <OurServices
                            ser={ser1Img}
                            serName="Digging Services"
                            serlink="/diggingservices"
                        >
                            {/* Pass the SerCatgory components as children */}
                            <SerCatgory serCatgory="1 . Manual Digging" />
                            <SerCatgory serCatgory="2 . Machine Digging" />

                        </OurServices>

                    </div>


                    <div className='col-lg-4'>

                        <OurServices
                            ser={sweeping}
                            serName="Outdoor Cleaning"
                            serlink="/outdoorCleaning"
                        >
                            <p className="serMinCont">
                                We provide comprehensive outdoor cleaning services from A to Z - from sweeping and pressure washing to driveway scrubbing, and more.
                            </p>

                        </OurServices>


                    </div>

                    <div className='col-lg-4'>

                        <OurServices
                            ser={farming}
                            serlink="/contactUs"
                            serName="Farming Assistance"
                        >
                            <p className="serMinCont">
                                We provide comprehensive farming assistance services, covering everything from land preparation and planting to crop maintenance and harvesting, ensuring your agricultural needs are met with care and efficiency.

                            </p>

                        </OurServices>


                    </div>


                </div>

            </div>




        </section >
    )
}

export default TotSer;