import React from "react";



import Banner1 from "../../assetes/images/services/irragition/main-banner.png";
import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/irragition/4.png";
import dripIrrgaition from "../../assetes/images/services/irragition/drip-irrgaition.png";
import sprinklerIrrgation from "../../assetes/images/services/irragition/sprinkler-irrgation.png";
import customIrrigation from "../../assetes/images/services/irragition/customIrrigation.png";
import birdImg from '../../assetes/images/bird.png';

// import Gallery from "./gallery1";
// import MainHeading from "./main-heading";

import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function ServicesTemp() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="">

            <div className="pt-5">
                <img src={Banner1} className="w-100 pt-4" alt="" />
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Our Expert Irrigation Services
                    </h1>

                    <h2 className="ser-sub-hed">
                        From Drip Irrigation to Sprinkler Systems – Tailored Solutions for Farms, Gardens, and Green Spaces
                    </h2>

                    <p className="ser-cm-fnt">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla repudiandae autem, id dolore adipisci qui. Illum molestiae sunt assumenda aspernatur at quam libero asperiores, accusantium vero tempora ut ipsa fuga?
                    </p>
                </div>
                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring generations of farming experience from rural Bangalore. As farmers ourselves, we understand the unique needs of local landscapes and crops, making us the best choice for your irrigation needs. Our deep-rooted expertise allows us to recommend and implement customized irrigation solutions that are not only efficient but also sustainable and cost-effective. From drip irrigation to sprinkler systems, we handle each project with precision and a commitment to preserving water and enhancing crop yield. With a practical approach grounded in real-world farming, we’re more than just a service provider – we’re partners dedicated to helping you grow.
                            </p>
                        </div>

                    </div>

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Efficient water usage, saving costs.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Improved plant health and yield.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Minimal maintenance systems.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Affordable and customizable for various spaces.
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Irrigation Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> Drip Irrigation Installation  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                Drip irrigation is an efficient way to deliver water directly to the roots of plants, minimizing waste and maximizing water usage. By using a network of tubes and emitters, water is slowly released at ground level, ensuring each plant receives the necessary hydration without oversaturation. This method not only conserves water but also reduces weed growth and prevents soil erosion.
                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                Choose Get Labourers for a professional installation, backed by generations of farming expertise and a commitment to efficient water management.
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={dripIrrgaition} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={sprinklerIrrgation} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span> Sprinkler System Installation </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt">
                                A sprinkler system is an effective irrigation solution designed to evenly distribute water over large areas, making it ideal for fields, gardens, and lawns. This method mimics natural rainfall, providing plants with consistent moisture and supporting healthy growth across the entire landscape. With adjustable sprinkler heads and timers, water usage is easily controlled, reducing waste and ensuring optimal hydration for your crops or greenery.
                            </p>

                            <p className="ser-cm-fnt">
                                Trust Get Labourers to help you achieve a lush, thriving landscape with our professional sprinkler system installations.
                            </p>

                            <FormPop />

                        </div>
                    </div>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 px-0 text-start pe-lg-4 order-2 order-md-1 px-lg-0 px-3 pt-4 pt-lg-0">
                            <h3 className="text-start ">

                                <span> Custom Irrigation Solutions  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt">
                                Every landscape is unique, and effective irrigation requires a system that is customized to its specific needs. Our Custom Irrigation Solutions at Get Labourers are designed with flexibility and precision to ensure that your crops, garden, or lawn receive exactly the right amount of water, when and where it’s needed. Whether you need a combination of drip irrigation, sprinklers, or advanced moisture sensors, we craft a solution that optimizes water usage and maximizes crop yield.
                            </p>

                            <p className="ser-cm-fnt">
                                Choose Get Labourers for custom irrigation solutions that are customized  to the demands of your land, enhancing productivity while conserving valuable resources.
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={customIrrigation} className="w-100 irg-img" alt="" srcset="" />
                        </div>



                    </div>



                </div>



            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. Do You Provide a Guarantee on Your Irrigation Products?</Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    Yes, we offer a <span className="text-green"> one-year guarantee </span>on all our irrigation products and systems. This guarantee covers any defects in materials and workmanship, ensuring that you have peace of mind and confidence in the quality of our installations. If any issues arise within the first year, simply contact us, and we will resolve the problem promptly at no additional cost.
                                    <br />
                                    Our goal is to provide reliable, long-lasting irrigation solutions, and this guarantee is a testament to our commitment to quality and customer satisfaction.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. Why should I choose drip irrigation?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Drip irrigation delivers water directly to the roots, minimizing waste and promoting healthier plant growth. It’s highly efficient for crops that require consistent moisture without oversaturation, conserving water and reducing weed growth.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. What are the benefits of a sprinkler system?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Sprinkler systems are ideal for larger areas, evenly distributing water over the landscape. They can be programmed to operate on a schedule, helping you save time and ensuring your crops or lawn receive adequate moisture.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. How long does the installation process take?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Installation time depends on the size and complexity of the project. Typically, we can install smaller systems in a day or two, while larger, more complex systems may take a bit longer. Our team will provide a clear timeline after assessing your site.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. Are your irrigation systems easy to maintain?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Absolutely. We prioritize simplicity and durability in all our installations. We also provide guidance on basic maintenance, and our team is available for support and any necessary repairs.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>6. What areas do you serve?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We currently serve clients across Bangalore. Contact us to see if we can assist with your specific location.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>7. How much water can I save with a proper irrigation system?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    A well-designed irrigation system can save up to 30-50% of water compared to traditional watering methods, depending on the type of system and specific crop requirements.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>8. How do I get started with Get Labourers for my irrigation needs?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Simply contact us, and we’ll set up an initial consultation. Our team will assess your land, discuss your goals, and propose the best irrigation solution to meet your requirements.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>



                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default ServicesTemp;