import React from 'react';
import './App.css';
import './assetes/style/services.css';
import Header2 from './pages/header2';

import Home from './pages/home';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/header';
import Footer from './components/footer';
import ServicesTemp from './pages/services/irrigationServices';
import WeedRemoval from './pages/services/weedRemoval';
import EstateCleaning from './pages/services/estate-cleaning';
import OrganicManuring from './pages/services/organic-manuring-soil-enrichment';
import DiggingServices from './pages/services/diggingservices';
import PlantPestControl from './pages/services/plant-pest-control';
import OutdoorCleaning from './pages/services/outdoorCleaning';
import MosquitoFogging from './pages/services/mosquitoFogging';
import FlowerPlantation from './pages/services/flowerPlantation';
import TotSer from './pages/services/totSer';

import About from './pages/about';
import ContactUs from './pages/contactUs';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">

      <React.StrictMode>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} /> {/* Changed About to Home */}
            <Route path="about" element={<About />} />
            <Route path="/irrigationServices" element={<ServicesTemp />} />
            <Route path="weedRemoval" element={<WeedRemoval />} />
            <Route path="contactUs" element={<ContactUs />} />
            <Route path="estate-cleaning" element={<EstateCleaning />} />
            <Route path="organic-manuring-soil-enrichment" element={<OrganicManuring />} />
            <Route path="diggingservices" element={<DiggingServices />} />
            <Route path="plant-pest-control" element={<PlantPestControl />} />
            <Route path="outdoorCleaning" element={<OutdoorCleaning />} />
            <Route path="mosquitoFogging" element={<MosquitoFogging />} />
            <Route path="flowerPlantation" element={<FlowerPlantation />} />
            <Route path="totSer" element={<TotSer />} />

            <Route path="header2" element={<Header2 />} />
            logo of the page
            {/* <Route path="tableau" element={<Tableau />} />
            <Route path="excel" element={<Excel />} /> */}
          </Routes>
        </BrowserRouter>
        <Footer />
      </React.StrictMode >

    </div>
  );
}

export default App;
