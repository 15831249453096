import React from "react";
import { OurServices, SerCatgory } from '../components/services';



import weedRemoval from '../assetes/images/services/weed-removal.png';
import manure from '../assetes/images/services/manure.jpeg';
import garbage from '../assetes/images/services/garbage.jpg';
import estateClean from '../assetes/images/services/realestate-cleaning/realestate-cleaning.png';
import foggingMachine from '../assetes/images/services/fogging-machine.jpg';
import irrigImg from '../assetes/images/services/irragition/drip-irrgaition.png';
import flowerplant from '../assetes/images/services/flower-plantition.png'

function AllServices() {
    return (
        <div>
            <div className='container'>
                <div className='row'>


                    <div className='col-lg-4'>

                        <OurServices
                            ser={weedRemoval}
                            serName="Weed Removal"
                            serlink="/weedRemoval"
                        >
                            {/* Pass the SerCatgory components as children */}
                            <SerCatgory serCatgory="1 . Manual weed control" />
                            <SerCatgory serCatgory="2 . Chemical Weed Control" />


                        </OurServices>

                    </div>

                    <div className='col-lg-4 soil-enrich'>

                        <OurServices
                            ser={manure}
                            serName="Organic Manuring & Soil Enrichment"
                            serlink="organic-manuring-soil-enrichment"
                        >
                            {/* Pass the SerCatgory components as children */}
                            <SerCatgory serCatgory="1 . Cow Dung Manure" />
                            <SerCatgory serCatgory="2 . Goat / Sheep Manure" />

                        </OurServices>

                    </div>

                    <div className='col-lg-4'>

                        <OurServices
                            ser={irrigImg}

                            serName="Irrigation Solutions"
                            serlink="/irrigationServices"
                        >
                            {/* Pass the SerCatgory components as children */}
                            <SerCatgory serCatgory="1 .Drip Irrigation" />
                            <SerCatgory serCatgory="2 .Sprinkler Systems" />


                        </OurServices>


                    </div>

                    <div className='col-lg-4'>

                        <OurServices
                            ser={estateClean}

                            serName={"Plots & Real Estate Cleaning"}
                            serlink="/estate-cleaning"
                        >
                            <p className="serMinCont">
                                We provide effective plant pest control services, from targeted treatments for common garden pests to preventive care solutions, ensuring your plants stay healthy and thriving.
                            </p>

                        </OurServices>


                    </div>

                    {/* <div className='col-lg-4'>

                        <OurServices
                            ser={garbage}

                            serName="Garbage Cleaning"
                            serlink="organic-manuring-soil-enrichment"
                        >
                            <p className="serMinCont">
                                We offer complete garbage cleaning services, from regular waste removal and bin washing to thorough debris cleanup and disposal, keeping your spaces spotless.
                            </p>

                        </OurServices>


                    </div> */}





                    <div className='col-lg-4'>

                        <OurServices
                            ser={foggingMachine}

                            serName="Mosquito Fogging"
                            serlink="mosquitoFogging"
                        >
                            <p className="serMinCont">

                                We eliminate mosquitoes at the root level, from larvae to adult mosquitoes, using advanced fogging techniques to provide you with a long-term solution for a mosquito-free environment.

                            </p>

                        </OurServices>


                    </div>



                    {/* <div className='col-lg-4'>

              <OurServices
                ser={sewageCle}

                serName="Gutter & Sewage Cleaning"
              >
                <p className="serMinCont">

                  Keep your property safe and clean with our professional gutter and sewage cleaning services. We clear blockages, remove debris, and ensure smooth water flow to prevent damage and maintain hygiene

                </p>

              </OurServices>


            </div> */}

                    <div className='col-lg-4'>

                        <OurServices
                            ser={flowerplant}
            
                            serName="Flower Plantation"
                            serlink="flowerPlantation"
                        >
                            <p className="serMinCont">

                                We enhance your outdoor spaces by planting vibrant, seasonal flowers, creating beautiful and sustainable landscapes.

                            </p>

                        </OurServices>


                    </div>



                </div>
            </div>
        </div >
    )
}


export default AllServices;