// import './App.css';
import MainBanner from '../components/bannerCor';

import MainHeading from '../components/main-heading';
import WorkSteps from '../components/workSteps';
import Testimonials from '../components/testimonials';
import Faqs from '../components/faqs';

import AllServices from './allServices';

import bookSer from '../assetes/images/working-steps/book-service.png';
import consultation from '../assetes/images/working-steps/consultation.png';
import quotation from '../assetes/images/working-steps/quotation.png';
import scheduleWork from '../assetes/images/working-steps/schedule-work.png';
import work from '../assetes/images/working-steps/gets-work.png';
import quality from '../assetes/images/working-steps/high-quality.png';
import payment from '../assetes/images/working-steps/payment-method.png';
import support from '../assetes/images/working-steps/ongoing-support.png';

import foggingMachine from '../assetes/images/services/fogging-machine.jpg';


import GalleryImg from '../components/gallery';
import gal1 from "../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";


// import { ContactUs } from './components/emailjs';


import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {

  // gallery images
  const images = [foggingMachine, gal1, gal5, gal6, gal2, gal3, gal4, gal7];

  return (
    <div className="home">

      {/* <Header /> */}
      {/* <BannerTwo /> */}

      <div className="bn-cont pt-5">
        <MainBanner />
      </div>

      {/* Our services */}

      <section className='py-5 my-lg-5 our-services'>
        <MainHeading

          mainheading="Our Services"

          mTagline="Your Project, Our Priority"

        />

        <AllServices />
        <a href='/totSer' className='all-ser-btn'>
          <span className=''>  All Services </span>
        </a>
      </section>

      {/* how we work */}
      <section className='py-5 howWeWork'>
        <MainHeading

          mainheading="How We Work"

          mTagline="From Start to Finish, We’ve Got You Covered"

        />
        <div className='container'>
          <div className='row'>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={bookSer}
                stepCount="Step 1"
                steps="Book a Service"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={consultation}
                stepCount="Step 2"
                steps="Consultation & Site Visit"
                extraInfo="(if necessary)"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={quotation}
                stepCount="Step 3"
                steps="Receive a Quotation"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={scheduleWork}
                stepCount="Step 4"
                steps="Schedule the Work"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={work}
                stepCount="Step 5"
                steps="Our Team Gets to Work"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={quality}
                stepCount="Step 6"
                steps="Quality Assurance"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={payment}
                stepCount="Step 7"
                steps="Payment & Feedback"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={support}
                stepCount="Step 8"
                steps="Ongoing Support
"

              />
            </div>

          </div>
        </div>

      </section>

      {/* Testimonials*/}

      <section className='py-5'>
        <MainHeading

          mainheading="Testimonials"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <Testimonials />
        </div>
      </section>


      {/* FAQ'S*/}

      <section className='py-5 cm-bg text-white faqs'>
        <MainHeading

          mainheading="FAQ'S"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <Faqs />
        </div>
      </section>

      {/* form testing */}

      {/* <ContactUs /> */}


      {/* Gallery*/}

      <section className='py-5'>
        <MainHeading

          mainheading="Gallery"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <GalleryImg images={images} />
        </div>
      </section>



      {/* footer */}

      {/* <Footer /> */}


    </div>
  );
}

export default Home;
