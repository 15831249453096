import React from "react";
import Banner1 from "../../assetes/images/services/organic-manure/organic-manure.png";


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import cowDung from "../../assetes/images/services/organic-manure/cow-dung.png";
import sheepDung from "../../assetes/images/services/organic-manure/sheep-goat-dung.png";
import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function OrganicManuring() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className=" pt-5">

            <div className="abt-banner mt-5">
                <img src={Banner1} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        <span> Organic Manuring</span> &  <span>Soil Enrichment</span>
                    </p>
                </div>
            </div>



            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Manuring & Soil Enrichment
                    </h1>

                    <h2 className="ser-sub-hed">
                        Enriching Soil Naturally for Healthier Growth
                    </h2>

                    <p className="ser-cm-fnt">
                        Organic manuring and soil enrichment are crucial for maintaining soil health, increasing crop yields, and fostering sustainable agricultural practices. By adding organic manure—such as compost, green manure, or animal manure—farmers enhance the soil's nutrient profile, improve its structure, and increase its water retention capabilities. Organic manures provide essential nutrients like nitrogen, phosphorus, and potassium, along with beneficial microbes that aid plant growth and strengthen soil resilience.


                    </p>

                    <p className="ser-cm-fnt">
                        Using organic manure reduces the dependency on synthetic fertilizers, promoting a balanced ecosystem and minimizing environmental impact. For both home gardeners and large-scale farmers, organic soil enrichment ensures healthier, more fertile soil and supports long-term agricultural sustainability.
                    </p>
                </div>

                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring years of hands-on experience from rural Bangalore, where nurturing soil and enhancing land productivity is essential. As local experts, we understand the unique needs of the region’s soil and climate, making us the trusted choice for organic manuring and soil enrichment. Our team employs natural, sustainable practices to boost soil fertility and health, utilizing rich, organic manure and time-tested techniques that promote nutrient-rich, resilient soil for long-term agricultural success.
                            </p>
                        </div>

                    </div>

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Enhanced Soil Fertility
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Improved Soil Structure
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Increased Water Retention
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Reduced Environmental Impact
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Organic Manuring & Soil Enrichment Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> Cow Manure  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                Cow manure is a rich, organic fertilizer that plays a vital role in sustainable farming and gardening. Packed with essential nutrients like nitrogen, phosphorus, and potassium, it improves soil fertility, boosts plant growth, and enhances crop yields naturally.

                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                Let us handle the hard work so you can enjoy a vibrant, thriving landscape!
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={cowDung} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={sheepDung} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span>Goat and Sheep Manure </span> <br />
                                <span className="ser-sub fs-5">
                                    " The Perfect Organic Boost for Your Soil "
                                </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>

                            <p className="ser-cm-fnt">

                                Goat and sheep manure are excellent natural fertilizers, packed with nutrients to promote healthy plant growth and improve soil quality. These organic manures are highly sought after by farmers and gardeners for their effectiveness and sustainability.

                            </p>

                            <p className="ser-cm-fnt">
                                Enhance your soil's fertility and achieve healthier plants with goat and sheep manure – nature's powerful fertilizer!
                            </p>



                            <FormPop />

                        </div>
                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. Why should I use cow manure?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    Cow manure is nutrient-rich and improves soil structure, water retention, and microbial activity. It provides a steady release of essential nutrients like nitrogen, phosphorus, and potassium.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. How is sheep and goat manure different from cow manure?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Sheep and goat manure are drier and more concentrated in nutrients compared to cow manure. They break down quickly and are great for plants that need a rapid nutrient boost.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. Is manure safe to use for all types of plants?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, cow and sheep & goat manures are safe for all types of plants, including vegetables, flowers, fruit trees, and lawns. However, ensure proper composting or curing to avoid over-fertilizing.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. Can I apply manure directly to my soil?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Cow manure is often used after composting for better results, while sheep & goat manure can be applied in small amounts directly or after mixing with soil to prevent nutrient overload.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. How does organic manure help the soil?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Organic manure enhances soil fertility by adding essential nutrients, improving aeration, and boosting water retention. It also promotes beneficial microbial activity for healthier soil.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>6. How much manure should I use?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The amount depends on your crop or garden size. As a general guideline:


                                </p>
                                <ul>
                                    <li>
                                        Cow manure: 2-3 kg per square meter.
                                    </li>
                                    <li>
                                        Sheep & goat manure: 1-2 kg per square meter due to its higher nutrient concentration.
                                    </li>
                                </ul>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>7. Is organic manure better than chemical fertilizers?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, organic manure is eco-friendly, improves long-term soil health, and reduces chemical buildup in the soil, unlike synthetic fertilizers that may harm the environment.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>8. Do you deliver manure to farms and gardens?</Accordion.Header>
                            <Accordion.Body>
                                <p>

                                    Yes, we provide delivery services for cow and sheep & goat manure. Contact us for delivery details and charges.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>9. What are the benefits of using sheep & goat manure in small gardens?</Accordion.Header>
                            <Accordion.Body>
                                <p>

                                    Sheep & goat manure is compact and easy to handle, making it ideal for small gardens. It provides a quick nutrient boost for healthy and vibrant plants.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>10. Do you offer bulk supplies for large-scale farming?</Accordion.Header>
                            <Accordion.Body>
                                <p>

                                   
                                    Yes, we cater to both small-scale gardeners and large-scale farmers. Reach out to us for bulk orders and pricing.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>



                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default OrganicManuring;