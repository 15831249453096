import React from "react";
import banner from '../../assetes/images/services/fogging/fogging.png';
import mosFog1 from '../../assetes/images/services/fogging/foging1.png';
import mosFog2 from '../../assetes/images/services/fogging/foging2.png';


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'

import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/fogging-machine.jpg";
import gal2 from "../../assetes/images/services/fogging-machine2.jpg";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function MosquitoFogging() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="pt-4">

            <div className="abt-banner pt-5">
                <img src={banner} className="w-100 pt-4" alt="" />
                <div className="pag-name">
                    <p>
                   <span> Mosquito Fogging </span> Services</p>
                </div>
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Mosquito Fogging
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Eliminate mosquitoes from the root and protect your home or business from mosquito-borne diseases."
                    </h2>

                    <p className="ser-cm-fnt">
                        Protect your surroundings from harmful mosquitoes with our reliable mosquito fogging service. Ensure a safe and healthy environment for your family and community by effectively eliminating mosquito breeding grounds.
                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">
                    {/* 
                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring years of hands-on experience from rural Bangalore, where managing land and controlling weeds is a daily task. As local experts, we understand the unique challenges posed by the region’s landscape and climate, making us the ideal choice for your weed removal needs. Our team uses a variety of eco-friendly, efficient techniques to tackle weed problems, from manual removal to chemical and mulching solutions.
                            </p>
                        </div>

                    </div> */}

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Kills Mosquitoes at the Root
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Promotes Health and Safety
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Fast and Effective Solution
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Eco-Friendly Approach
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Weed Removal Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> What is Mosquito Fogging?  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                Mosquito fogging is a highly effective pest control method designed to eliminate mosquitoes and their breeding grounds. This process involves dispersing a fine mist of insecticide into the air, targeting adult mosquitoes and larvae in hard-to-reach areas. It is commonly used in residential, commercial, and outdoor spaces to create a safe and mosquito-free environment.
                            </p>


                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={mosFog1} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={mosFog2} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span> How Does Mosquito Fogging Work? </span> <br />

                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>


                            <ul>
                                <li>
                                    A specialized fogging machine is loaded with a safe insecticide solution.
                                </li>

                                <li>
                                    The machine releases a fine mist or "fog" that penetrates even the smallest spaces, effectively killing mosquitoes on contact.
                                </li>

                                <li>
                                    The treatment leaves a protective layer that helps prevent mosquitoes from returning for an extended period.
                                </li>

                                <li>
                                    Homeowners or property managers are advised on steps to maintain the results, such as removing stagnant water and maintaining cleanliness.
                                </li>

                            </ul>


                            <FormPop />

                        </div>
                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. Why choose mosquito fogging?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    Mosquito fogging removes mosquitoes from the root by targeting their breeding grounds and eliminating both adult mosquitoes and larvae. This ensures long-lasting results and significantly reduces the mosquito population in your area.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. How long do the effects of mosquito fogging last?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The effects can last for weeks, depending on the environment and whether potential breeding grounds like stagnant water are managed. Regular treatments can extend the mosquito-free period.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. How often should chemical treatments be applied?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The frequency depends on the type of pest and level of infestation. Our experts will provide a customized plan for optimal results.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. What preparations are needed before fogging?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Remove food, utensils, and pets from the area. Close all windows and doors inside your home, and cover sensitive items like aquariums.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. How quickly can I see results after fogging?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    You will notice a significant reduction in mosquitoes immediately after the service, with continued improvement as the treatment takes full effect.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>6. Can mosquito fogging help prevent diseases?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, by reducing the mosquito population, fogging helps minimize the risk of mosquito-borne diseases like dengue, malaria, and chikungunya.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default MosquitoFogging;