import React from "react";
import ContForm from "../components/cont-form";
import contactbanner from "../assetes/images/banner-img/contact-banner.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import MainHeading from "../components/main-heading";

import log from '../assetes/images/get-labourers.png'


function ContactUs() {
    return (
        <div className="pt-5">
            <div className="abt-banner mt-5 pt-5">
                <img src={contactbanner} className="w-100" alt="" />
                <div className="pag-name">
                    <p>Contact Us</p>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex align-items-center mt-5">
                    <MainHeading

                        mainheading="Get In Touch"

                        mTagline="Your Project, Our Priority"

                    />
                    <div className="col-lg-6">
                        <div className="form-section">
                            <ContForm />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src={log} className="w-25 mt-5" alt="" srcset="" />

                        <div className="cont-info mt-3">

                            <p>
                                <a href="tel:+91 7676038974">
                                    <FontAwesomeIcon icon="fa-solid fa-phone" />  7676038974
                                </a>
                            </p>

                            <p>

                                <a href="mailto:getlabourers@gmail.com">
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" />  getlabourers@gmail.com
                                </a>

                            </p>

                            <div className="social-media">

                                <a href="http://">
                                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                </a>

                                <a href="http://">
                                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                                </a>

                                <a href="http://">
                                    <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                                </a>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;