import React from "react";
import Banner1 from "../../assetes/images/services/pit-digging/banner.png";


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import manualdig from "../../assetes/images/services/pit-digging/manual-digging.png";
import machinedigging from "../../assetes/images/services/pit-digging/machine-digging.png";

import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function DiggingServices() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className=" pt-5">

            <div className="abt-banner mt-5">
                <img src={Banner1} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        Digging Services
                    </p>
                </div>
            </div>



            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Digging Services
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Precision Digging for Every Need!"
                    </h2>

                    <p className="ser-cm-fnt">
                        Looking for reliable and efficient digging solutions? Our skilled team provides professional digging services for pits, sumps, foundations, and more. Whether it’s for construction, landscaping, or other needs, we ensure quality work with precision and speed. Contact us today for affordable and hassle-free digging services!

                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring a wealth of hands-on experience rooted in the heart of rural Bangalore. Our expertise comes from years of working on diverse terrains, understanding the soil and environmental conditions unique to the region. Whether it's pit digging, sump creation, or groundwork preparation, we combine traditional methods with modern efficiency to deliver reliable and precise results. With a commitment to quality and a focus on sustainable practices, we ensure your digging projects are completed on time and tailored to your specific needs. Trust Get Labourers for digging services that lay the foundation for your success!
                            </p>
                        </div>

                    </div>

                    {/* <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Enhanced Soil Fertility
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Improved Soil Structure
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Increased Water Retention
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Reduced Environmental Impact
                        </div>
                    </div> */}

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Digging Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> Manual Digging  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">

                                Manual digging is a precise and eco-friendly method for tasks like pit creation, groundwork, and landscaping. It allows for careful handling of the soil, making it ideal for projects in confined or sensitive areas. With skilled labor and traditional tools, manual digging ensures accuracy and minimal environmental impact.

                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={manualdig} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={machinedigging} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span>Machine Digging </span> <br />

                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>

                            <p className="ser-cm-fnt">

                                Machine digging is a fast and efficient solution for large-scale or time-sensitive projects. Using advanced equipment, it ensures precision and speed for tasks like foundation work, trenching, and excavation. Ideal for both commercial and residential needs, machine digging saves time, reduces labor, and delivers consistent, high-quality results.

                            </p>

                            <FormPop />

                        </div>
                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What types of digging services do you offer?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    We provide services like pit digging, sump digging, trenching, foundation digging, and more for various residential, commercial, and agricultural needs.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. How do I know if I need manual or machine digging?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Manual digging is ideal for smaller, confined spaces or sensitive areas, while machine digging is best for large-scale projects requiring speed and efficiency.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. Do you handle site cleanup after digging?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we ensure the site is cleaned and prepared according to your requirements once the digging work is complete.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. Can you work on uneven or rocky terrain?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Absolutely! Our skilled team is equipped to handle challenging terrains with precision and care.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. How much do your digging services cost?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Our pricing depends on the size and complexity of the project. Contact us for a customized quote.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default DiggingServices;