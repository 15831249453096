import React from "react";
// import contactbanner from "../assetes/images/banner-img/contact-banner.png";


import banner from '../../assetes/images/services/Flower-plantation/flower-plant.png';
import flower1 from '../../assetes/images/services/Flower-plantation/flower1.png';

import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from '../../assetes/images/services/Flower-plantation/flower2.png'

import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function FlowerPlantation() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="pt-1">

            <div className="abt-banner pt-5 mt-5">
                <img src={banner} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        Flower Plantation
                    </p>
                </div>
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Flower Plantation
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Blossom your world with Get Labourers’ expert touch!"
                    </h2>

                    <p className="ser-cm-fnt">
                        Flower plantation is a rewarding practice that enhances the beauty of your surroundings while contributing to a healthier environment. By planting a variety of vibrant flowers, you can create a visually stunning space that attracts pollinators like bees and butterflies, supporting biodiversity. Get Labourers provides expert flower plantation services, ensuring proper planting techniques, soil preparation, and aftercare to help your flowers thrive. Whether for your home, office, or community spaces, we bring your vision to life with our skilled labor and dedication to quality.


                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100 h-50 " />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                Get Labourers offers expert and reliable flower plantation services for gardens, landscapes, and properties of all sizes. Rooted in a rich heritage, our ancestors were dedicated flower growers, and we proudly carry forward their legacy. Hailing from one of Bangalore’s largest flower-producing regions, our team is well-versed in the art and science of cultivating vibrant, healthy flowers.
                            </p>
                        </div>

                    </div>

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Enhances Aesthetic Appeal
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Boosts Mood and Well-Being
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Supports Biodiversity
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Preserves Tradition
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Flower Plantation Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                {/* <span> Weed and Vegetation Control </span> */}
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                At Get Labourers, we take pride in offering comprehensive flower plantation services according to your needs. Whether you’re looking to create a vibrant garden, enhance your commercial property, or revamp an outdoor space, our team of skilled professionals is here to help.
                            </p>

                            <p>
                                Drawing from a legacy of flower cultivation passed down through generations, and hailing from one of Bangalore’s most renowned flower-producing regions, we bring unmatched expertise to every project. From soil preparation and plant selection to planting and aftercare, we handle every step with precision and care.
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={flower1} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What is the best method for weed removal?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    The best method depends on the type of weeds, the size of the area, and your preferences. We offer manual removal, chemical control, plastic mulching, and soil solarization. Our experts will assess your space and recommend the most effective and eco-friendly solution.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. What does your plot and real estate cleaning service include?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Our cleaning services include removal of debris, overgrown vegetation, and weeds. We also handle garbage removal, outdoor area cleaning, and can prepare plots for future construction or landscaping.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. Why should I invest in plot cleaning services?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Regular plot cleaning prevents overgrowth, minimizes pest risks and ensures that the area is ready for potential development. It also enhances the property’s appearance and value, making it more attractive to potential buyers or tenants.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. How often should I schedule a plot cleaning service?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    For large or heavily vegetated plots, we recommend scheduling cleaning services once every two months to keep the area well-maintained. Smaller plots might require once every three months, depending on the vegetation and upkeep needs.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                5. Do you offer one-time cleaning or recurring maintenance plans?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we offer both one-time cleaning services and recurring maintenance plans. Our team can work with you to create a schedule that meets your needs, whether it’s a one-off job or ongoing maintenance.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                6. Are there any specific types of properties you specialize in cleaning?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We specialize in cleaning residential plots, commercial properties, vacant lands, and real estate intended for sale or rental. Our services are adaptable to various types of plots and real estate properties.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                7. Do you provide waste disposal services for the debris removed?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we handle all aspects of waste removal and disposal, including safe removal of all debris, plant material, garbage, and any other unwanted materials from your property.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>
                                8.How much do your plot cleaning services cost?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Pricing varies based on the size, condition, and specific cleaning requirements of the property. We offer free quotes, so feel free to contact us for a customized estimate.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>
                                9. What should I do to prepare my property for cleaning?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    You don’t need to do much! Our team will handle everything from start to finish. However, if there are any valuable or delicate items on-site, we recommend removing or securing them before our team arrives.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>
                                10. Is your team equipped for overgrown or large-area cleaning jobs?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, our team is experienced and equipped with the necessary tools and machinery to handle heavily overgrown areas and large plots, ensuring thorough and efficient cleaning.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>



                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default FlowerPlantation;