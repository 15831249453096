import React from "react";
import Accordion from 'react-bootstrap/Accordion';

export default function Faqs() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>

                    1. What services does Get Labourers provide?

                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        We offer a wide range of labor services, including digging, sump and pit digging, garbage cleaning, outdoor cleaning, mosquito fogging, gutter and sewage cleaning, weed removal, farming assistance, and organic manuring.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    2. How do I book a service?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        Booking is simple! Visit our website, select the service you need, fill in your details, and confirm your booking. You can also contact us directly for assistance.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    3. Are your laborers skilled and experienced?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        Yes, our team is trained and experienced in delivering efficient and reliable labor services tailored to your needs.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    4. What areas do you currently serve?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">

                        We primarily operate in Bangalore and nearby regions. Check with us to confirm availability in your specific area.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    5. Can I customize the services according to my needs?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">

                        Absolutely! We work closely with our customers to understand their requirements and provide tailored solutions.
                    </p>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    6. How are your charges calculated?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">

                        Our charges depend on the type of service, the scale of the work, and the duration required. Contact us for a free quote.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    7. How quickly can I expect service after booking?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        We strive to provide services as soon as possible, often within 24-48 hours of booking, depending on the availability of our team.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

        </Accordion>

    )

}