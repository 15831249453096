import React from "react";
import Banner1 from "../../assetes/images/services/outdoor-cleaning/outdoor-cleaning.png";


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import presWash from "../../assetes/images/services/outdoor-cleaning/pres-wash.png";
import debricleaning from "../../assetes/images/services/outdoor-cleaning/debri-cleaning.png";
import customIrrigation from "../../assetes/images/services/weed-removal/chemical-weed-control.png";


import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function OutdoorCleaning() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="">

            <div className="abt-banner pt-5">
                <img src={Banner1} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        Outdoor Cleaning
                    </p>
                </div>
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Outdoor Cleaning
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Transform Your Outdoors with Sparkling Clean Spaces!"
                    </h2>

                    <p className="ser-cm-fnt">
                        Our Outdoor Cleaning Services offer comprehensive solutions to maintain the cleanliness and beauty of your exterior spaces. Whether it's cleaning driveways, patios, walkways, or your garden area, we provide a thorough cleaning using advanced equipment and eco-friendly products. We remove dirt, debris, moss, and stains to create a fresh and inviting environment. Regular outdoor cleaning not only enhances the aesthetic appeal of your property but also helps to prevent damage from dirt buildup and weathering. Let us help you keep your outdoor spaces pristine, safe, and welcoming all year round.
                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">
                    {/* 
                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring years of hands-on experience from rural Bangalore, where managing land and controlling weeds is a daily task. As local experts, we understand the unique challenges posed by the region’s landscape and climate, making us the ideal choice for your weed removal needs. Our team uses a variety of eco-friendly, efficient techniques to tackle weed problems, from manual removal to chemical and mulching solutions.
                            </p>
                        </div>

                    </div> */}

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Improved Aesthetics
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Health and Hygiene
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Prevents Damage
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Keeps Pests Away
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Outdoor Cleaning Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h4 className="text-start ">

                                <span> Driveway and Pathway Cleaning:  </span>

                            </h4>
                            <p className="ser-cm-fnt pe-lg-4">

                                We remove dirt, stains, moss, and algae from driveways, walkways, and paths to restore their cleanliness and safety.

                            </p>

                            <h4 className="text-start ">

                                <span> Garden Cleaning: </span>

                            </h4>
                            <p className="ser-cm-fnt pe-lg-4">
                                Our team clears fallen leaves, weeds, and other debris from your garden to maintain its beauty and health.

                            </p>

                            <h4 className="text-start ">

                                <span>  Pressure Washing: </span>

                            </h4>
                            <p className="ser-cm-fnt pe-lg-4">

                                Using high-pressure equipment, we clean outdoor surfaces like walls, fences, and driveways, removing tough stains, dirt, and grime.
                            </p>


                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={presWash} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={debricleaning} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h4 className="text-start ">

                                <span> Trash and Debris Removal: </span>

                            </h4>
                            <p className="ser-cm-fnt pe-lg-4">

                                We dispose of trash, leaves, and other debris from your outdoor spaces, leaving them neat and tidy.

                            </p>


                            <h4 className="text-start ">

                                <span> Snake and Rodent Deterrent Cleaning: </span>

                            </h4>
                            <p className="ser-cm-fnt pe-lg-4">

                                By clearing away clutter, debris, and overgrown areas, we help reduce hiding spots for snakes and rodents, making your outdoor space safer.
                            </p>


                            <FormPop />

                        </div>
                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What areas are covered in outdoor cleaning services?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>

                                    Our outdoor cleaning services include driveways, patios, gardens, pathways, fences, gutters, pool areas, and other exterior spaces around your property.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. How often should outdoor cleaning be done?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>

                                    The frequency depends on the environment and usage. For most areas, a thorough cleaning every 3–6 months is recommended to maintain cleanliness and prevent damage.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. Do you use eco-friendly cleaning methods?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>

                                    Yes, we use eco-friendly products and techniques that are safe for the environment, plants, and pets.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. Can outdoor cleaning help deter snakes and rodents?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Absolutely! By removing debris, overgrown vegetation, and clutter, our cleaning services reduce hiding spots, making the area less attractive to snakes and rodents.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default OutdoorCleaning;