import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../assetes/main.css";

import Logo from '../assetes/images/get-labourers.png';

function Header() {
    return (
        <div className="navbar-cont">

            <Navbar expand="lg" className="nav-rel">
                <Container>
                    <Navbar.Brand href="/" className="logo-brand-sec text-start">
                        <img src={Logo} className="nav-log" alt="" />
                    </Navbar.Brand>

                    <div className="nsv-abs d-lg-none">
                        <Nav.Link href="#link" className="px-4 call-us-now">
                            <i class="fa-solid fa-phone nav-callbtn"></i>

                            <span className='ps-2'>
                                7676038974
                            </span>

                        </Nav.Link>
                    </div>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='ps-lg-5'>
                        <Nav className="me-auto d-flex justify-content-between align-items-center w-100 ms-lg-5 ps-lg-5">

                            <Nav.Link href="/">
                                Home
                            </Nav.Link>
                            <Nav.Link href="about">
                                About
                            </Nav.Link>

                            <NavDropdown title="Our Services" id="basic-nav-dropdown">

                                <NavDropdown.Item href="weedRemoval">
                                    Weed Removal
                                </NavDropdown.Item>

                                <NavDropdown.Item href="irrigationServices">
                                    Irrigation System Installation
                                </NavDropdown.Item>

                                <NavDropdown.Item href="estate-cleaning">
                                    Estate Cleaning
                                </NavDropdown.Item>

                                <NavDropdown.Item href="organic-manuring-soil-enrichment">
                                    Organic Manuring & Soil Enrichment
                                </NavDropdown.Item>

                                <NavDropdown.Item href="diggingservices">
                                    Digging Services
                                </NavDropdown.Item>

                                <NavDropdown.Item href="plant-pest-control">
                                    Plant Pest Control
                                </NavDropdown.Item>

                                {/* <NavDropdown.Item href="outdoorCleaning" className='mb-2'>
                                    Outdoor Cleaning
                                </NavDropdown.Item> */}


                                <NavDropdown.Item href="flowerPlantation" className='mb-2'>
                                    Flower Plantation
                                </NavDropdown.Item>

                                <NavDropdown.Item href="mosquitoFogging" className='mb-2'>
                                    Mosquito Fogging
                                </NavDropdown.Item>



                                <NavDropdown.Item href="#action/3.3" className="all-ser-drop">
                                    <span class="">All Services <i class="fa-solid fa-arrow-right kn-mor-drp"></i></span>
                                </NavDropdown.Item>


                                <NavDropdown.Item href="#action/3.4">
                                </NavDropdown.Item>
                            </NavDropdown>

                            {/* <Nav.Link href="weedRemoval">
                                Blogs
                            </Nav.Link> */}


                            <Nav.Link href="contactUs">Contact Us</Nav.Link>
                            <Nav.Link href="#link" className="px-4 py-1 call-us-now d-no">
                                <i class="fa-solid fa-phone nav-callbtn"></i>

                                <span className='ps-2'>
                                    7676038974
                                </span>

                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>


                </Container>
            </Navbar>
        </div>
    );
}

export default Header;