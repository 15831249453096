import React from "react";
import Banner1 from "../../assetes/images/services/pest-control/plant-pest-controll.png";


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import organicControl from "../../assetes/images/services/pest-control/organic-pest-cont.png";
import sprinklerIrrgation from "../../assetes/images/services/irragition/customIrrigation.png";
import customIrrigation from "../../assetes/images/services/weed-removal/chemical-weed-control.png";


import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function PlantPestControl() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="pt-5 ">

            <div className="abt-banner pt-5">
                <img src={Banner1} className="w-100" alt="" />
                <div className="pag-name">
                    <p>
                        <span> Plant Pest </span> Control
                    </p>
                </div>
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Plant Pest Control
                    </h1>

                    <h2 className="ser-sub-hed">
                        "Protect Your Plants, Safeguard Your Growth!"
                    </h2>

                    <p className="ser-cm-fnt">
                        Effective plant pest control is essential to maintain the health and productivity of your garden or farm. Our innovative pest management solutions are designed to protect your plants from harmful insects and diseases while preserving the environment. By using eco-friendly techniques and targeted treatments, we ensure your plants thrive without the use of harsh chemicals. Say goodbye to pests and hello to a lush, healthy landscape!
                    </p>


                </div>

                <div className="prob-sol pt-5 pb-2">
                    {/* 
                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring years of hands-on experience from rural Bangalore, where managing land and controlling weeds is a daily task. As local experts, we understand the unique challenges posed by the region’s landscape and climate, making us the ideal choice for your weed removal needs. Our team uses a variety of eco-friendly, efficient techniques to tackle weed problems, from manual removal to chemical and mulching solutions.
                            </p>
                        </div>

                    </div> */}

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Healthier Plants
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Eco-Friendly Solutions
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Prevent pest damage early
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Enhanced Yield and Quality
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Weed Removal Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> Organic Pest Control  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                Our Organic Pest Control Services offer a safe and eco-friendly solution to protect your plants from harmful pests without compromising the environment. We use natural products like neem oil, garlic-based sprays, and organic soaps to eliminate pests effectively while safeguarding beneficial insects. Our expert team also employs techniques such as companion planting and introducing natural predators like ladybugs to create a balanced and pest-free environment. Whether it's for your garden, farm, or landscaping needs, our organic methods ensure your plants thrive while maintaining soil health and environmental safety. Choose us for sustainable pest management that promotes healthier plants and a greener planet!

                            </p>


                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={organicControl} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={customIrrigation} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span> Chemical Control Services </span> <br />

                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt">

                                Our Chemical Pest Control Services provide a powerful and reliable solution for managing stubborn pest infestations. Using approved and targeted pesticides, we eliminate pests quickly and efficiently while minimizing risks to non-target plants and beneficial organisms. Our trained professionals ensure the safe application of chemicals, adhering to all safety standards to protect your plants, soil, and surroundings. Whether dealing with insects, fungi, or weeds, our chemical control methods are tailored to your specific needs, offering effective pest management for gardens, farms, and landscapes. Trust us for a solution that delivers lasting results and restores your plants to full health.
                            </p>


                            <FormPop />

                        </div>
                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What is chemical pest control?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    Chemical pest control involves using pesticides or insecticides to eliminate pests that damage plants. It is a quick and effective method to manage severe infestations.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. Are the chemicals safe for my plants?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we use approved and targeted chemicals designed to control pests without harming your plants when applied correctly.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. How often should chemical treatments be applied?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The frequency depends on the type of pest and level of infestation. Our experts will provide a customized plan for optimal results.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. Do I need to prepare before the service?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We recommend clearing the area of pets, covering sensitive plants, and following specific instructions provided by our team to ensure effective treatment.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. What is organic pest control?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Organic pest control uses natural methods and eco-friendly products, such as neem oil, garlic sprays, and biological agents, to manage pests without harming the environment.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>6.  Is organic pest control effective?</Accordion.Header>
                            <Accordion.Body>
                                <p>

                                    Yes, organic pest control is highly effective when applied correctly. It not only eliminates pests but also promotes a healthier ecosystem by preserving beneficial insects and soil health.


                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>7. Is organic pest control safe for pets and children?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Absolutely! Organic pest control methods are safe for pets and children, making it an ideal choice for home gardens and residential areas.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>


                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default PlantPestControl;