import React from "react";
import Abtbanner from "../assetes/images/banner-img/about.png"
import MisVis from "../assetes/images/miss-viss.png";
import Accordion from 'react-bootstrap/Accordion';

function About() {
    return (
        <div className="pt-5">

            <div className="abt-banner mt-5">
                <img src={Abtbanner} className="w-100" alt="" />
                <div className="pag-name">
                    <p>About Us</p>
                </div>
            </div>
            <div className="container abt-font">

                <h2 className="pt-5 main-pg-header">Who We Are</h2>

                <p className="abt-font">
                    We are a team rooted in generations of agricultural expertise, originating from the rural heart of Bangalore. Our journey began with a deep understanding of the land, farming practices, and the hard work required to sustain our communities. Over the years, we’ve expanded to bring our skills and knowledge to a wider audience, offering dependable, skilled labor services that bridge traditional values with modern demands.
                </p>

                <p className="abt-font">
                    Each member of our team is experienced and dedicated, with decades spent honing their craft in fields and on projects. They bring not only their hands but their expertise, ready to adapt to any job required. Whether as lifelong farmers or as experienced day laborers, our team is driven by a commitment to provide high-quality service. Their practical experience and flexible approach mean they are fully equipped to support you across a range of services, from digging and irrigation to cleaning and maintenance.
                </p>


                <p className="abt-font">
                    At Get Labourers, we blend traditional values with modern demands to provide you with reliable, expert service. Whether it’s digging, irrigation, or outdoor maintenance, we approach every task with integrity and a strong work ethic that ensures it’s done right.
                </p>


            </div>

            <div className="container">

                <h2 className="pt-5 main-pg-header">
                    Our Mission and Values
                </h2>

                <div className="row d-flex align-items-center">

                    <h3 className="ps-lg-2">
                        Our Mission
                    </h3>
                    <p className="abt-font">
                        "At Get Labourers, our mission is to bring skilled, reliable, and hardworking labor services to the community, drawing on our agricultural roots and commitment to excellence. We strive to meet the needs of every client with respect, efficiency, and a dedication to quality. By offering trusted labor solutions, we aim to support not only individuals and businesses but also contribute to the growth and sustainability of our local economy."
                    </p>

                    <div className="col-lg-6">




                        <h3 className="text-start ps-lg-2">
                            Our Values
                        </h3>

                        <ul className="text-start abt-font  ">
                            <li>
                                Reliability
                            </li>

                            <li>
                                Professionalism

                            </li>

                            <li>
                                Quality Service

                            </li>

                            <li>
                                Community Support

                            </li>

                            <li>
                                Sustainability (if relevant, especially for services like organic manuring or eco-friendly practices)

                            </li>
                        </ul>

                    </div>

                    <div className="col-lg-6">
                        <img src={MisVis} className="w-100" alt="" srcset="" />
                    </div>

                    <h3 className="ps-lg-2 pt-4">
                        Our Vision
                    </h3>
                    <p className="abt-font">
                        "Our vision is to be the leading provider of labor services in Bangalore, known for our integrity, reliability, and dedication to community. We see a future where every household, farm, and business can rely on expert labor assistance, grounded in experience and built on trust. We aspire to make a positive impact in every project we take on, creating a legacy of excellence that empowers communities and supports sustainable growth."


                    </p>
                </div>



            </div>


            {/* why us */}
            
            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    Why Choose Us?
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. Deep Agricultural Roots
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p className="abt-font">
                                    With generations of experience rooted in farming and fieldwork, we bring a unique blend of practical knowledge and hands-on expertise to every job. Our team understands the land, the labor, and the quality of work needed to deliver excellent results.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. Skilled and Dependable Team
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="abt-font">
                                    Each member of our team is highly skilled, with years of experience in their craft. From farmers to laborers, everyone brings dedication, reliability, and flexibility, ensuring we can handle a wide range of services to meet your specific needs.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                3. Commitment to Quality and Precision
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="abt-font">
                                    We believe in doing things right the first time. With an eye for detail and a commitment to quality, our team ensures that every project—whether digging, cleaning, or irrigation installation—is completed to the highest standard.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                4. Community-Focused and Trustworthy
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="abt-font">
                                    As a local service provider, we are dedicated to serving our community with integrity. Our clients can count on us for honest, dependable service that builds trust and creates lasting relationships.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                5. Flexible and Adaptive Approach
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="abt-font">
                                    No two projects are the same, and we understand the importance of adapting to meet the unique needs of each job. Our team is ready to provide customized support, whether it’s a small project or a large-scale task.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                6. Eco-Conscious Solutions
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="abt-font">
                                    Whenever possible, we prioritize eco-friendly practices, particularly in services like organic manuring and sustainable farming support. We care about the environment and work to make a positive impact through our services.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>

            </div>


        </div >
    )
}

export default About;